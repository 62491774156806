/*from INSPINIA_WB0R5L90S/SCSS/_md-skin.scss */
$md-shadow-main: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .2), 0 1px 5px 0 rgba(0, 0, 0, .12);

.kpi-list {
    /*not yet defined*/
}

@mixin kpi-color($color) {
    border: 1px solid $color;
    .kpi-icon {
        background-color: $color;
    }
    .kpi-text {
        color: $color;
    }
}

.kpi-item {
    display: inline-block;
    position: relative;
    width: 100%;
    height: 80px;
    padding: 0;
    margin: 0;
    background-color: white;
    .kpi-icon {
        display: inline-block;
        position: absolute;
        top: 0px;
        left: 0px;
        width: 80px;
        height: 100%;
        line-height: 80px;
        padding: 10px;
        color: white;
        i {
            font-size: 3.5em;
        }
    }
    .kpi-text {
        display: inline-block;
        width: 100%;
        padding: 10px;
        padding-left: 90px;

        h2 {
            font-size: 30px;
            font-weight: bold;
            margin: 0;
        }
        > span {
            font-size: 20px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            display: block;
        }
    }

    &-navy {@include kpi-color($navy);}
    &-yellow {@include kpi-color($yellow);}
    &-lazur {@include kpi-color($lazur);}
    &-blue {@include kpi-color($blue);}
    &-dark-gray {@include kpi-color($dark-gray);}
    &-red {@include kpi-color($red);}
}

.md-skin .kpi-item {
    box-shadow: $md-shadow-main;
}

.kpi-list {
    .ibox {
        margin: 0;
    }
    .icon i {
        font-size: 50px;
        height: 50px;
        color: $icon-gray;
        margin-right: 12px;
    }

    .image {
        height: 42px;
        margin-top: 3px;
        margin-right: 12px;
    }

    ibox-content > div {
        height: 50px;
        line-height: 50px;
        vertical-align: middle;
    }

    .kpi-name {
        font-family: "Open Sans";
        font-size: 16px;
        font-weight: 600;
        color: #000000;
        text-transform: uppercase;
        vertical-align: top;

        &.lines1 span {
            margin-top: -10px;
            line-height: 50px;
            vertical-align: middle;
        }
        &.lines2 span {
            height: 50px;
            line-height: 25px;
            vertical-align: middle;
        }
    }
    .kpi-value {
        height: 50px;
        float: right;

        h2 {
            display: inline-block;
            line-height: 50px;
            vertical-align: middle;
            margin: 0;
            padding: 0;
        }
        .number {
            color: $primary-color;
        }
    }
    h2 {
        color: #DDDDDD;
    }
}
