@import "../vendor/inspinia/scss/variables";
@import "variables";
@import "kpi";
@import "analysis";

@font-face {
    font-family: 'logo';
    src: url('../fonts/logo-webfont.woff2') format('woff2'),
    url('../fonts/logo-webfont.woff') format('woff'),
    url('../fonts/logo-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}


span.insurtec {
    font-family: 'logo', serif;
}

/* css style overrides */
body {
    font-family: "gibsonregular", "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    min-height: 100%;
}

i.red {
    color: red;
}

i.green{
    color: green;
}

.gray {
    color: #a9a9a9;
}

.nl2br {
    white-space: pre-line;
}
.font-weight-thin {
    font-weight: 300;
}

#wrapper {
    overflow: visible;
}
#wrapper.login {
    background: url("../images/login_bg.jpg") 100% 100%;
}
.login img.logo {
    width: 248px;
}
.login img.ssl {
    width: 128px;
}

.verticalRadioGroup label {
    display: block;
}

.no-sidebar {
    #page-wrapper {
        margin: 0;
    }
}

/* Added this so the page is not too big. Default page-wrapper makes page way too big! */
#page-wrapper {
    min-height: 0;
}

.col-xs-2\.5,
.col-sm-2\.5,
.col-md-2\.5,
.col-lg-2\.5 {
    position: relative;
    min-height: 1px;
    padding-right: 10px;
    padding-left: 10px;
}

.col-xs-2\.5 {
    width: 20%;
    float: left;
}

@media (min-width: 768px) {
    .col-sm-2\.5 {
        width: 20%;
        float: left;
    }
}

@media (min-width: 992px) {
    .col-md-2\.5 {
        width: 20%;
        float: left;
    }
}

@media (min-width: 1200px) {
    .col-lg-2\.5 {
        width: 20%;
        float: left;
    }
}

.ibox {
    border-bottom: $colored-border-bottom-size solid $primary-color;

    .ibox-title {
        background-color: #FCFCFC;
        border-bottom: 1px solid #EEEEEE;
    }

    .ibox-title h5 .fa {
        color: $primary-color;
        margin-right: 5px;
        font-size: 20px;
    }

    .ibox-title h5 {
        color: #000000;
        font-family: "Open Sans";
        font-weight: 600;
        font-size: 13px;
    }
}

.p-t-xxs {padding-top: 1px;}
.p-t-xs {padding-top: 5px;}
.p-t-sm {padding-top: 10px;}
.p-t {padding-top: 15px;}
.p-t-md {padding-top: 20px;}
.p-t-lg {padding-top: 30px;}
.p-t-xl {padding-top: 40px;}

h2 .ssl {
    color: $dark-gray;

    border: 2px solid $dark-gray;
    border-radius: 10px;
    padding: 6px 20px;
    margin-top: -5px;

    i {
        font-size: 24px;
        vertical-align: middle;
    }

    span {
        margin-left: 10px;
        font-weight: normal;
        font-size: 14px;
        vertical-align: middle;
    }
}

.wizard {
    a {
        font-weight: lighter;
        text-transform: uppercase;
        color: $dark-gray;

        vertical-align: middle;

        i {
            font-size: 16px;
            font-weight:lighter;
            vertical-align: middle;
            margin: 0 10px;
            margin-top: -3px;
        }

        span {
            vertical-align: middle;
        }

        &:hover {
            color: $primary-color;
        }

        &.next {
            margin-left: 20px;
        }
        &.next.disabled, &.back.disabled {
            color: $gray;
            cursor: default;
        }
    }
}

.sk-spinner-three-bounce div {
    background-color: $primary-color;
}

.md-skin {
    .navbar, .navbar--main {
        background-color: white !important;
    }
    .nav.navbar-right > li > a {
        color: black;
    }

    .nav > li.active > a {
        color: darken($text-color, 5%);
        font-weight: 700;
    }

    .navbar--menu {
        .nav {
            &.left {
                & > li {
                    padding: 0;
                    width: 200px;
                    align-items: center;
                    border-bottom: 2px solid transparent;
                }
                & > li > a {
                    padding: 16px 20px 12px 25px;
                    text-transform: uppercase;
                    text-align: center;
                }
                & > li:hover {
                    background: $gray !important;
                    border-bottom: 2px solid $gray;
                }
                & > li > a:hover {
                    background: none;
                }
                & > li.active {
                    background: lighten($gray, 2%);
                    border-left: none;
                    padding: 0;
                    border-bottom: 2px solid $primary-color;
                }
                & > li.active:hover {
                    background: $gray !important;
                    border-bottom: 2px solid darken($primary-color, 10%);
                }
            }
            &.right {
                float: right;
                margin-right: 22px;
                button {
                    text-transform: uppercase;
                    background-color: $primary-color;
                    border-color: $primary-color;
                    color: #FFFFFF;
                }
                button:hover {
                    background-color: darken($primary-color, 5%);
                    border-color: darken($primary-color, 5%);
                }
            }
        }
    }
}

.navbar {
    &--main {
        margin: 0;

        .navbar-brand {
            font-size: 30px;
            color: #fff;
            font-weight: bold;
            height: $navbar-height;
            margin: 5px 0;
        }

        .navbar-welcome {
            margin-left: 73px;
            padding: 0;
            line-height: $navbar-height;
            height: $navbar-height;
            vertical-align: bottom;
            font-size: 16px;
            color: #000000;
            font-family: "Open Sans";
            font-weight: normal;
        }

        .navbar-logo {
            line-height: $navbar-height;
            margin-left: 30px;
            margin-top: 2px;
            vertical-align: middle;
            float: left;
        }

        .navbar-top-links {
            margin-top: 10px;
        }
    }

    &--menu {
        min-height: 0;
        margin-bottom: 0;
    }
}

.navbar-collapse {
    padding: 0;
}

.md-skin {
    .page-heading {
        height: 72px;
        margin: 30px 20px 0;
        border-bottom: $colored-border-bottom-size solid $primary-color !important;

        .breadcrumb-icon i.fa {
            font-size: 42px;
            color: $icon-gray;
            margin: 0 20px 0 0;
        }
        h2 {
            text-transform: uppercase;
            margin: 20px 0 0 0;

            color: #000000;
            font-family: "Open Sans";
            font-size: 16px;
            font-weight: bold;
        }

        .right {
            float: right;
            display: table;
            height: 72px;

            .vcenter {
                display: table-cell;
                vertical-align: middle;
            }
        }
    }
}

button.table-filter {
    margin-top: -10px;
    margin-bottom: -10px;
    height: 49px;
    min-width: 100px;
    border: 0;
    background-color: transparent;
    border-bottom: 1px solid transparent;

    &.active {
        border-bottom: 1px solid red;
    }
    &:hover {
        background-color: $gray;
    }
}

.table {

    &-link-row {

        & > tbody > tr > td {
            padding: 0 !important;
        }

        & > tbody > tr > td > a,
        & > tbody > tr > td > span {
            color: inherit;
            display: block;
            width: 100%;
            padding: 8px;
        }

        & > tbody > tr > td > span {
            cursor: pointer;
        }

        & > tbody > tr > td.no-link {
            padding: 8px !important;
        }
    }

    &-row-mark {

        & > thead > tr > th:first-child,
        & > tbody > tr > td:first-child {
            border-left: 3px solid transparent;
        }

        & > tbody > tr.marked {

            & > td:first-child {
                border-left-color: #19aa8d;
            }

            &.mark-danger > td:first-child {
                border-left-color: $red;
            }

            &.mark-warning > td:first-child {
                border-left-color: $yellow;
            }
        }
    }

    &-offer-request {

        .label-offer-end {
            display: none;
        }

        & > tbody > tr.marked {

            .label-offer-end {
                display: inline-block;
                margin-left: 5px;
            }

            i.fa {
                margin-left: 4px;
            }

            &.mark-danger i.fa {
                color: $red;
            }

            &.mark-warning i.fa {
                color: $yellow;
            }
        }
    }
}

.react-bs-table td.ranking,
.react-bs-table td.ranking,
.react-bs-table td.ranking {
    cursor: pointer
}

.ibox {
    .no-ibox & {
        border: none;
        box-shadow: none;
    }
}

.form {

    &-horizontal {

        &.label-left .control-label {
            text-align: left;
        }
    }
}
.radio-horizontal {
    display: inline-block!important;
    width: auto !important;
    label {
        width: 100% !important;
    }
}

.react-datepicker {

    &__input-container {

        .form-group & {
            display: block;
        }
    }
}

.btn {
    text-transform: uppercase;
    font-weight: 100;

    &.btn-danger {
        background-color: $primary-color;
    }

    &.btn-dark {
        background-color: darken($dark-gray, 50%);
        color: white;
        &:hover {
            background-color: darken($dark-gray, 100%);
        }
    }
}

.right-align input {
    text-align: right;
}

small.adornment {
    color: #999;
}

.no-data {
    color: $dark-gray;
}

.feed {

    &-element {
        & > a {
            color: inherit;
        }
    }

}

.f {
    &-s {
        &-default {
            font-size: inherit;
        }

        &-14 {
            font-size: 14px;
        }

        &-16 {
            font-size: 16px;
        }
    }
}

.fh {
    &-300 {
        height: 300px;
    }

    &-600 {
        height: 600px;
    }
}

.h {
    &-100 {
        min-height: 100px;
    }
    &-400 {
        min-height: 400px;
    }
    &-500 {
        min-height: 500px;
    }
    &-600 {
        min-height: 600px;
    }
}

.fw {
    &-150 {
        width: 150px;
    }
}

.w {
    &-auto {
        width: auto;
    }
}

.pointer {
    cursor: pointer;
}

.pointer-help {
    cursor: help;
}

.full-height-scroll {
    height: 100%;
    overflow-y: scroll;
}

.chat-view {
    .loading {
        position: absolute;
        top: 0;
        padding: 5px;
        background-color: #eeeeee;
    }
    .chat-discussion-wrapper {
        height: 350px;
    }
}

.chat-discussion {
    background-color: #f3f6fb;
}

.chat-message {
    padding: 10px 0;
}

.chat-message .message-author {
    color: #337ab7;
}

.chat-discussion .chat-message.right .message {
    margin-right: 40px;
}

.chat-discussion .chat-message.left .message {
    background-color: #f3f3f4;
    margin-left: 40px;
}

.chat-discussion .chat-message.left.new .message {
    background-color: #f4dcdf;
}

.chat-users .chat-user .chat-avatar {
    width: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.chat-users {
    margin-left: 0;
    margin-right: -30px;
}

$chat-send-button-width: 130px;
.chat-form {
    margin-bottom: 0;

    button {
        border-radius: 0;
        float: right;
        width: $chat-send-button-width;
        height: 54px;
    }

    .chat-message-holder {
        width: 100%;
        padding-right: $chat-send-button-width;
        height: 54px;
    }

    textarea {
        width: 100%;
    }
}

.array-field-remove {
    position: relative;
    height: 0;

    & > a,
    & > button {
        position: absolute;
        right: -40px;
        top: 0
    }
}

.loginscreen.middle-box {
    max-width: 500px;
    width: 410px;
}

.verifyscreen.middle-box {
    max-width: 500px;
}

.dataTables_filter {
    float: right;
    margin-bottom: 15px;

    &::after {
        clear: both;
    }
}

.dt-buttons {
    float: left;
}

.dataTables_length {
    float: right;
    margin-right: 50px;
}

.nav-notifications-list{
    max-height: 300px;
    overflow-y: scroll;
}

/* Offer Request Details Page */
.timeline-item .date.created i {
}
.timeline-item .date.pending i {
    background: #ffe69c;
}
.timeline-item .date.published i {
    background: #c8ffc7;
}
.timeline-item .date.rejected i {
    background: #ffd1d3;
}
.timeline-item .date.withdrawn i {
    background: #ffd1d3;
}

.scrollbars-always::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
}
.scrollbars-always::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0,0,0,.5);
    -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
}

.max-height-200-scroll {
    max-height: 200px;
    overflow-y: auto;
}
.max-height-1000-scroll {
    max-height: 1000px;
    overflow-y: auto;
}

/* File Upload */
.well.accept {
    background-color: #ceedb8;
}
.well.reject {
    background-color: #edbcb6;
}
.file-upload i.fa {
    margin-right: 20px;
    font-size: 20px;
    color: black;
}

/* statistics */
.table-striped > tbody > tr.own {
    background-color: scale-color($navy, $lightness: 85%);
}

tbody > tr.tr-odd {
    background-color: #f9f9f9;
}



/*
 * Stepper (horizontal)
 */
.vbottom {
    float: none;
    display: inline-block;
    vertical-align: bottom;
}
.stepper {
    display: flex;
}

.step-line,
.step:before,
.step:after {
    position: absolute;
    bottom: 6px;
    height: 2px;
    content: '';
    background-color: LightGrey;
}

.step {
    flex: 1;
    position: relative;
    min-height: 35px;
    padding-bottom: 23px;
    cursor: pointer;

    &.disabled {
        cursor: initial;
    }
    &:before {
        left: 0px;
        width: 50%;
    }

    &:after {
        width: 50%;
        right: 0px;
    }

    &.current:before {
        z-index: 1;
    }
    &.current:after {
        z-index: -1;
    }
    &.current.complete:after {
        z-index: 1;
    }
    &.current .step-dot {
        bottom: 0px;
        width: 14px;
        height: 14px;
        left: calc(50% - 7px);
        border: 3px solid LightGrey;
        border-radius: 7px;
    }

    &.complete .step-dot,
    &.current .step-dot {
        background-color: Black;
    }

    &.complete .step-label {
        color: Grey;
    }
    &.current .step-label {
        color: Black;
    }
    /*
    &.error .step-label:after {
        color: Red;
        content: ' !'
    }
    */
    &.error .step-label {
        color: Red;
    }
    &.error .step-dot {
        background-color: $red;
        z-index: 1;
    }
    &.error.current .step-dot {
        background-color: Black;
        z-index: 0;
    }
}

.step-line {
    left: -15px;
    right: -15px;
}

.step-line.complete,
.step.complete:before,
.step.complete:after,
.step.current:before {
    background-color: Black;
}

.step-dot {
    display: inline-block;
    position: absolute;
    width: 8px;
    height: 8px;
    left: calc(50% - 4px);
    background-color: LightGrey;
    content: '';
    bottom: 3px;
    border-radius: 4px;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
}

.step-label {
    width: 100%;
    text-align: center;
    position: relative;
    bottom: 0px;
    color: LightGrey;
}

.watermark {
    position: absolute;
    top: 30px;
    left: -35px;

    width: 160px;
    height: 30px;
    line-height: 30px;
    text-align: center;

    background-color: red;
    color: white;
    font-size: 20px;

    -ms-transform: rotate(-45deg); /* IE 9 */
    -webkit-transform: rotate(-45deg); /* Chrome, Safari, Opera */
    transform: rotate(-45deg);
}
